import settings from '@settings';

export const newsContainer = {
	display: 'flex',
	flexDirection:'row',
	align: 'flex-start',
	gap: settings.sizes.medium,
	flexWrap: 'wrap',
	width: '100%'

};