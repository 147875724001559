import React from 'react';

import Container from '@components/Container';
import FlexBox from '@components/layout/FlexBox';
import Wrapper from '@components/layout/Wrapper';
import NewsCard from '@components/surfaces/NewsCard';
import useGetNews from '@hooks/useGetNews';
import Layout from '@mainComponents/Layout';
import settings from '@settings';
import PropTypes from 'prop-types';

import { newsContainer } from './styles';




const News = ({ pageContext: {uri} }) => {
	const news = useGetNews();
	return (
		<Layout 
			path={uri}
			headerType='page-without-image'
			showHeader
		>
			<Wrapper>
				<Container>
					<div style={newsContainer}>
						<FlexBox
							direction='row'
							justify='space-between'
							align='flex-start'
							gap={settings.sizes.medium}
							styles={{marginBottom: settings.sizes.large}}
							hasFullWidth
							wrap='wrap'
						>
							{news.map(item => (
								<NewsCard
									id={item.id}
									key={item.id}
									title={item.title}
									image={item.image}
									date={item.date}
									content={item.content}
								/>
							))}
						</FlexBox>
					</div>
				</Container>
			</Wrapper>
		</Layout>
	);
};

News.propTypes = {
	pageContext: PropTypes.object.isRequired
};

export default News;