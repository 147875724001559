/* eslint-disable react/prop-types */
import React from 'react';

import News from '@content/News';
import SiteHead from '@mainComponents/SiteHead';

const NewsSite = pageContext => <News pageContext={pageContext} />;

export const Head = ({location}) => <SiteHead location={location} />;

export default NewsSite;