import { graphql, useStaticQuery } from 'gatsby';

const useGetAthlete = () => {
	const athletes = useStaticQuery(graphql`
		query queryAllPlayers {
			allGraphCmsAthlete {
				edges {
					node {
						birthday
						id
						remoteId
						facebook
						description
						instagram
						name
						stage
						profile
						interview {
							html
						}
						nation {
							name
							short
							flag{
								url
							}
						}
						portrait {
							remoteId
							gatsbyImageData(
								aspectRatio: 1.5, 
								quality: 90, 
								height: 600
							)
						}
					}
				}
			}
		}
	`);
	
	const getAthlete = id => athletes.allGraphCmsAthlete.edges.find(athlete => athlete.node.remoteId === id)?.node;

	return ({
		getAthlete,
		athletes: athletes.allGraphCmsAthlete.edges.map(athlete => athlete.node )
	});
};

export default useGetAthlete;